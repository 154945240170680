* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
  }
  
  .navbar {
	position: fixed;
	top: 0;
	width: 100vw;
	background-color: black;
	display: flex;
	justify-content: space-between; /* Space between logo and nav-links container */
	align-items: center;
	z-index: 1000;
  }
  
  .logo {
	color: black;
	font-size: 2rem;
  }
  
  .nav-links-container {
	display: flex;
	justify-content: center; /* Center the nav links */
	flex: 1;
  }
  
  .nav-links {
	list-style: none;
	display: flex;
  }
  
  .MuiBox-root.css-zftxr2:focus-visible {
	outline: none !important;
  }
  
  .MuiBox-root.css-srz161:focus-visible {
	outline: none !important;
  }
  
  .nav-links li {
	margin-right: 20px;
  }
  
  .nav-links li a {
	color: white;
	font-size: 3vmin;
	text-decoration: none;
	position: relative;
	overflow: hidden;
	border-bottom: 2px solid transparent;
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  .nav-links li a:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: #FAE5E2;
	transform: scaleX(0);
	transition: transform 0.3s ease;
  }
  
  .nav-links li a:hover:before {
	transform: scaleX(1);
  }
  
  .nav-links li a:hover {
	color: #C62717;
  }
  
  .dropdown {
	position: relative;
  }
  
  .dropdown-content {
	position: absolute;
	display: none;
	background-color: #fff;
	padding: 10px;
	animation: fadeIn 0.3s ease;
	width: max-content;
  }
  
  .dropdown:hover .dropdown-content {
	display: block;
  }
  
  .dropdown-content a {
	display: block;
	color: black;
	text-decoration: none;
	padding: 5px 0;
  }
  
  .dropdown-content a:hover {
	background-color: white;
  }
  
  @media screen and (max-width: 950px) {
	.nav-links {
	  display: flex;
	  flex-direction: column;
	  position: fixed;
	  top: 6.2%;
	  right: -100%;
	  height: 100%;
	  width: 100%;
	  background-color: white;
	  padding: 20px 0px 20px 20px;
	  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	  transition: right 0.5s ease;
	  z-index: 2;
	}
  
	.nav-links.mobile li {
	  border-bottom: 2px solid #f1f1f1;
	  margin-bottom: 20px;
	}
  
	.nav-links.mobile li:last-child {
	  border-bottom: none;
	}
  
	.nav-links.mobile {
	  right: 0;
	}
  
	.nav-links.mobile {
	  display: flex;
	  transform: translateX(0);
	}
  
	.dropdown-content {
	  display: none;
	  position: relative;
	  background-color: #FAF9F6;
	  width: 100%;
	  padding: 20px;
	  animation: fadeIn 0.9s ease;
	}
  
	.nav-links.mobile {
	  display: flex;
	}
  
	.toggle-button {
	  display: block;
	  background-color: transparent;
	  color: #f1f1f1;
	  border: none;
	  font-size: 2rem;
	  font-weight: bold;
	  cursor: pointer;
	}
  
	.toggle-button:focus {
	  outline: none;
	}
  
	.nav-links li .dropdown {
	  left: 0;
	  right: auto;
	}
  
	.nav-links li a {
	  color: black;
	  font-size: 1.2rem;
	  text-decoration: none;
	  position: relative;
	  overflow: hidden;
	  font-weight: 20px;
	}
  
	.nav-links.mobile li a:hover {
	  color: #C62717;
	}
  }
  
  .css-i4bv87-MuiSvgIcon-roo {
	font-size: 1vmin;
  }
  