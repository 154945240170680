@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

* {
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
}

:root {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



body {
  margin: 0 auto; /* Center horizontally */
  place-items: center;
  min-height: 100vh;
  /* max-width: 1300px;  */
}




button {
  border-radius: 7px;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:focus,
button:focus-visible {
  
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #Ffff;
  }
  button {
    background-color: #f9f9f9;
  }
}


.MuiBox-root css-19e9bpl {
  border: none !important;
  outline : none !important;
}
